'use client';

import { Content } from '@prismicio/client';

import BlogListItem from '@/components/molecules/BlogListItem';

const Articles = ({ items }: { items: Content.BlogPostDocument<string>[] }) => {
  return (
    <div className="mb-10 flex h-full flex-col items-center justify-between gap-8 lg:mb-12 lg:flex-row lg:items-stretch lg:gap-5">
      {items.map((item) => {
        return <BlogListItem key={item.id} item={item} />;
      })}
    </div>
  );
};

export default Articles;
