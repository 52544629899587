import React from 'react';
import clsx from 'clsx';

import TableBody from '@/components/molecules/TableBody';
import { CryptoTableType } from '@/utils/types';
import FeesBody from '@/components/molecules/FeesBody';
import DesktopTableSkeleton from './partials/DesktopTableSkeleton';

const headers = {
  buyTable: ['Name', 'Buy Price', 'Flat Fees', '24h Change', ''],
  sellTable: ['Name', 'Sell Price', '24h Change', ''],
  buyFeesAndLimits: ['Price', 'Flat Fee', 'Limit Per Transaction', 'Daily Limit'],
  sellFeesAndLimits: ['Price', 'Limit Per Transaction', 'Daily Limit'],
};

const DesktopTable = ({ rows, variant, feesRow, tooltip, isLoading }: CryptoTableType) => {
  if (isLoading) {
    return <DesktopTableSkeleton />;
  }

  return (
    <table className="crypto-table mt-8 hidden w-full table-fixed border-separate border-spacing-0 lg:table">
      <thead className="text-primary-600">
        <tr className="first:child:pl-6 last:child:pr-6 last:child:text-end">
          {feesRow && (
            <th
              className="w-1/3 bg-primary-200 py-3.5 text-left text-h8 font-bold first:rounded-l-lg last:rounded-r-lg"
              scope="col"
            >
              <div className="hidden items-center gap-x-2 lg:flex">
                {feesRow}
                {tooltip}
              </div>
            </th>
          )}
          {headers[variant].map((header) => (
            <th
              className={clsx(
                'bg-primary-200 py-3.5 text-left text-h8 font-bold first:rounded-l-lg last:rounded-r-lg',
                {
                  'w-1/4': header === 'Name' && (variant == 'buyTable' || variant == 'sellTable'),
                },
              )}
              key={header}
              scope="col"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      {variant == 'buyTable' || variant == 'sellTable' ? (
        <TableBody rows={rows} variant={variant} />
      ) : (
        <FeesBody feesRow={feesRow} rows={rows} variant={variant} />
      )}
    </table>
  );
};

export default DesktopTable;
