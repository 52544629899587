'use client';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useMemo, useState } from 'react';

import { FilterPill } from '@/components/molecules/FilterPill';

import { ArticleBox } from './partials/ArticleBox';
import { filterItemsByYear, getFilters, sortItemsByDate } from './utils';

/**
 * Props for `Articles`.
 */
export type ArticlesProps = SliceComponentProps<Content.ArticlesSlice>;

/**
 * Component for "Articles" Slices.
 */
const Articles = ({ slice }: ArticlesProps): JSX.Element => {
  const sortedItemsByDate = useMemo(() => sortItemsByDate(slice.items), [slice.items]);
  const filters = getFilters(sortedItemsByDate);
  const [activeFilter, setActiveFilter] = useState(filters[0]);
  const filteredItems = filterItemsByYear(sortedItemsByDate, activeFilter);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="o-container o-container--xl my-44"
    >
      <div className="no-scrollbar mb-[3.75rem] flex flex-row gap-2 overflow-x-scroll">
        {filters.map((filter) => (
          <FilterPill
            key={filter}
            isActive={activeFilter === filter}
            label={filter}
            onClick={() => {
              setActiveFilter(filter);
            }}
            className="transition-all"
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-x-5 gap-y-16">
        {filteredItems.map((item) => (
          <ArticleBox key={item.title} {...item} />
        ))}
      </div>
    </section>
  );
};

export default Articles;
