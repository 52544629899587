import { format } from 'date-fns/format';
import { PrismicNextLink } from '@prismicio/next';

import { ArticleBoxProps } from './types';

export const ArticleBox = ({
  title,
  content,
  author,
  publishDate,
  linkToArticle,
}: ArticleBoxProps) => {
  return (
    <PrismicNextLink
      className="flex max-w-[22.6875rem] flex-col border-t border-t-gray-300 pt-8 hover:opacity-60"
      field={linkToArticle}
    >
      <h4 className="mb-3 line-clamp-4 text-body-5 font-bold text-gray-600 child:inline">
        {title}
      </h4>
      <p className="mb-6 line-clamp-3 text-body-7 text-gray-500 child:inline">{content}</p>
      <div className="flex flex-row items-center gap-2.5 text-body-8">
        <p className="text-gray-500">{author}</p>
        {publishDate && (
          <>
            <div className="h-2.5 w-[.0625rem] bg-gray-500" />
            <p className="font-semibold text-gray-600">{format(publishDate, 'MMM dd, yyyy')}</p>
          </>
        )}
      </div>
    </PrismicNextLink>
  );
};
