import { TooltipContentProps } from './types';

const TooltipContent = ({ title, description }: TooltipContentProps) => {
  return (
    <div className="max-w-60">
      {title && <p className="mb-2 text-caption-2 font-semibold text-gray-600">{title}</p>}
      <p className="text-caption-2 text-gray-500">{description}</p>
    </div>
  );
};

export default TooltipContent;
