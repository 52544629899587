'use client';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { PrismicNextImage } from '@prismicio/next';

/**
 * Props for `PartnersSlider`.
 */
export type PartnersSliderProps = SliceComponentProps<Content.PartnersSliderSlice>;

/**
 * Component for "PartnersSlider" Slices.
 */
const PartnersSlider = ({ slice }: PartnersSliderProps): JSX.Element => {
  const [emblaRef] = useEmblaCarousel({ loop: true, align: slice.primary.align }, [
    Autoplay({
      delay: 5000,
      stopOnInteraction: false,
    }),
  ]);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="my-44"
    >
      <h2 className="mb-16 text-center text-h2 font-bold">{slice.primary.header}</h2>
      <div className="relative">
        <div
          className="overflow-hidden pl-2 pr-4 opacity-80 grayscale md:pl-0 md:pr-0"
          ref={emblaRef}
        >
          <div className="grid w-full grid-flow-col md:auto-cols-[25%]">
            {slice.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className="my-auto w-44 max-w-44 px-3 md:w-auto md:max-w-none md:px-4"
                >
                  <div className="relative h-20 w-full lg:h-28 2xl:h-44">
                    <PrismicNextImage
                      className="absolute bottom-1/2 w-full translate-y-1/2 rounded-lg"
                      field={item.image}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSlider;
