import { Location } from '@/utils/types';
import { Marker, MarkerProps } from '@react-google-maps/api';
import LocationTooltip from '../LocationTooltip';
import { CoinsDocumentDataCoinsItem, Simplify } from '@/prismicio-types';

interface Props extends Omit<MarkerProps, 'position'> {
  item: Location;
  onClick: (e: google.maps.MapMouseEvent) => void;
  onClose: () => void;
  openedTooltipId: string;
  isBuyAndSell: boolean;
  isMobile: boolean;
  availableCoins: {
    buy: Simplify<CoinsDocumentDataCoinsItem>[];
    sell: Simplify<CoinsDocumentDataCoinsItem>[];
  };
}

const MapMarker = ({
  item,
  onClick,
  openedTooltipId,
  onClose,
  isMobile,
  isBuyAndSell,
  availableCoins,
  ...rest
}: Props) => {
  const position = {
    lat: +item.location.latitude,
    lng: +item.location.longitude,
  };

  return (
    <Marker
      onClick={onClick}
      icon={isBuyAndSell ? '/assets/svg/circle-buy-and-sell.svg' : '/assets/svg/circle-buy.svg'}
      key={item.id}
      position={position}
      {...rest}
    >
      {openedTooltipId === item.id && (
        <LocationTooltip
          isBuyAndSell={isBuyAndSell}
          onClose={onClose}
          location={item}
          availableCoins={availableCoins}
          openedTooltipId={openedTooltipId}
          position={position}
          isMobile={isMobile}
        />
      )}
    </Marker>
  );
};

export default MapMarker;
