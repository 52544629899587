import TagSkeleton from '@/components/atoms/Tag/partials/TagSkeleton';

const OpenClosedSkeleton = () => {
  return (
    <div className="flex animate-pulse flex-row items-center">
      <TagSkeleton />
      <div className="mx-2 h-1 w-1 rounded-full bg-gray-200"></div>
      <div className="h-6 w-24 rounded-md bg-gray-200"></div>
    </div>
  );
};

export default OpenClosedSkeleton;
