import React from 'react';
import { useController } from 'react-hook-form';
import { Select as NativeSelect } from '@headlessui/react';
import clsx from 'clsx';

import { ChevronDownIcon } from '@/public/assets/svg';

import type { FormSelectInputProps, SelectInputProps } from './types';

const Select = React.forwardRef<HTMLSelectElement, SelectInputProps>(
  ({ label, options, onChange, value = options[0].value }, ref) => {
    const selectedOption = options.find((option) => option.value === value);

    return (
      <div className="flex w-full flex-col">
        <label className="mb-3 text-body-8 font-semibold text-gray-600">{label}</label>
        <div className="relative">
          <NativeSelect
            ref={ref}
            onChange={(e) => onChange(e.target.value)}
            value={selectedOption?.value}
            className={clsx(
              'w-full appearance-none rounded border border-gray-200 p-3 placeholder:text-body-8',
              'cursor-pointer text-body-8 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none',
            )}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </NativeSelect>
          <ChevronDownIcon
            className="group pointer-events-none absolute right-3.5 top-3.5 size-4 h-4 w-4 fill-white/60"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  },
);

Select.displayName = 'Select';

export const FormSelect = ({ name, ...rest }: FormSelectInputProps) => {
  const { field, fieldState } = useController({ name });

  return (
    <Select
      {...field}
      {...rest}
      onChange={(value) => {
        field.onChange(value);
      }}
      error={fieldState.error?.message}
    />
  );
};

export default Select;
