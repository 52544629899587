'use client';
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import Tag from '@/components/atoms/Tag';
import { parseLocationDetails } from '@/utils';

import OpenClosedSkeleton from './partials/OpenClosedSkeleton';
import { OpenClosedProps } from './types';

const OpenClosed = ({ locationDetails, reverse }: OpenClosedProps) => {
  const [clientRendered, setClientRendered] = useState(false);
  const { openNow, openTime, closeHour } = parseLocationDetails(locationDetails);
  const is24Hours = openTime === '24/7' || openTime === '24h';
  const openText = useMemo(() => {
    if (is24Hours) {
      return openTime;
    }

    return `Open Until ${closeHour}`;
  }, [is24Hours, closeHour, openTime]);

  useEffect(() => {
    setClientRendered(true);
  }, []);

  if (!clientRendered) {
    return <OpenClosedSkeleton />;
  }

  return (
    <div className={clsx('flex items-center gap-x-2', reverse && 'flex-row-reverse')}>
      {openNow === null ? (
        <>
          <Tag className="min-w-max" variant="red">
            Temporary Closed
          </Tag>
        </>
      ) : openNow ? (
        <>
          <Tag className="min-w-max" variant="green">
            Open
          </Tag>
          • <span>{openText}</span>
        </>
      ) : (
        <>
          <Tag className="min-w-max" variant="red">
            Closed
          </Tag>
          • <span>Opens at {openTime}</span>
        </>
      )}
    </div>
  );
};

export default OpenClosed;
