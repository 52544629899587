import clsx from 'clsx';

import { Location } from '@/utils/types';
import DirectionsIcon from '@/public/assets/svg/directions.svg';
import ArrowRightIcon from '@/public/assets/svg/arrow-right.svg';
import Direction from '@/public/assets/svg/direction.svg';
import Link from '@/components/atoms/Link';
import { ROUTES } from '@/routes';
import LinkButton from '@/components/atoms/LinkButton';
import { pushGTMEventForATM } from '@/utils/gtm';
import {
  ATM_LISTING_GET_DIRECTIONS_CLICK_EVENT,
  ATM_MAP_DIRECTIONS_CLICK_EVENT,
  ATM_MAP_STORES_LIST_ITEM_CLICK_EVENT,
} from '@/utils/gtm/consts';

import OpenClosed from '../OpenClosed';

type Props = {
  item: Location;
  onChangeLocation: (location: Location) => void;
};

const LocationItem = ({ item, onChangeLocation }: Props) => {
  return (
    <div
      role="button"
      onClick={() => {
        onChangeLocation(item);
      }}
      className={clsx(
        item.isTwoWay || item.is_two_way ? 'border-l-primary-600' : 'border-l-green-600',
        'flex justify-between rounded-[0.625rem] border  border-l-4 border-gray-200 bg-white p-5 pr-5 shadow-elevation-4 hover:bg-gray-50 lg:p-6',
      )}
    >
      <div className="w-full">
        <h4 className="mb-2 line-clamp-1 text-body-5 font-bold">{item.name}</h4>
        <p className="mb-3 line-clamp-1 text-tag text-gray-500">{item.fullAddress}</p>
        <div className="mb-3 flex gap-x-2 text-tag">
          <OpenClosed locationDetails={item.location} />
        </div>
        <div className="grid grid-cols-2 gap-x-1">
          <LinkButton
            className="!h-10 whitespace-nowrap lg:hidden"
            variant="secondary"
            href={item.googleMapUrl}
            onClick={(event) => {
              event.stopPropagation();
              pushGTMEventForATM(item, ATM_LISTING_GET_DIRECTIONS_CLICK_EVENT);
            }}
          >
            <DirectionsIcon className="mr-2 min-h-5 min-w-5 text-white" /> Get directions
          </LinkButton>
          <Link
            className="flex h-10 items-center justify-center rounded-button border border-gray-600 px-0 text-button font-medium hover:opacity-50 lg:justify-start lg:border-none"
            href={ROUTES.location(item.location)}
            onClick={(event) => {
              event.stopPropagation();
              pushGTMEventForATM(item, ATM_MAP_STORES_LIST_ITEM_CLICK_EVENT);
            }}
          >
            Store details <ArrowRightIcon className="hidden lg:block" />
          </Link>
        </div>
      </div>
      <Link
        className="hidden flex-col items-center justify-center border-l border-gray-200 pl-5 hover:opacity-50 lg:flex"
        target="_blank"
        href={item.googleMapUrl || ''}
        onClick={(event) => {
          event.stopPropagation();

          pushGTMEventForATM(item, ATM_MAP_DIRECTIONS_CLICK_EVENT);
        }}
      >
        <Direction />
        Directions
      </Link>
    </div>
  );
};

export default LocationItem;
