import React, { useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel';
import { CarouselArrowNextIcon, CarouselArrowPreviousIcon } from '@/public/assets/svg';

import { ButtonProps, UsePrevNextButtonsType } from './types';

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
  onButtonClick?: (emblaApi: EmblaCarouselType) => void,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;

    emblaApi.scrollPrev();
    onButtonClick?.(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    emblaApi.scrollNext();
    onButtonClick?.(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

export const PrevButton: React.FC<ButtonProps> = (props) => {
  const { ...restProps } = props;

  return (
    <button
      className="flex h-[2.875rem] w-[2.875rem] cursor-pointer items-center justify-center"
      type="button"
      aria-label="prev button"
      {...restProps}
    >
      <svg className="h-full w-full" viewBox="0 0 100 100">
        <circle
          className="stroke-gray-200"
          strokeWidth="1.5"
          cx="50"
          cy="50"
          r="48"
          fill="white"
        ></circle>
      </svg>
      <div className="absolute">
        <CarouselArrowPreviousIcon width={20} height={20} />
      </div>
    </button>
  );
};

export const NextButton: React.FC<ButtonProps> = (props) => {
  const { progress, ...restProps } = props;

  return (
    <button
      className="relative flex h-[2.875rem] w-[2.875rem] cursor-pointer items-center justify-center"
      type="button"
      aria-label="next button"
      {...restProps}
    >
      <svg className="h-full w-full" viewBox="0 0 100 100">
        <circle
          className="stroke-gray-200"
          strokeWidth="1.5"
          cx="50"
          cy="50"
          r="48"
          fill="transparent"
        ></circle>
        <circle
          className="progress-ring__circle stroke-primary-600"
          strokeWidth="1.5"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="48"
          fill="white"
          strokeDasharray="301"
          strokeDashoffset={`calc(301 - (301 * ${progress}) / 100)`}
        ></circle>
      </svg>
      <div className="absolute">
        <CarouselArrowNextIcon width={20} height={20} className="text-gray-600" />
      </div>
    </button>
  );
};
