'use client';
import { useRef } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { FilterPill } from '@/components/molecules/FilterPill';
import BlogListItem from '@/components/molecules/BlogListItem';

import { ContentProps } from './types';
import { filterBlogsByCategory } from './utils';

const BlogPostsWithFiltersContent = ({ filters, blogs }: ContentProps) => {
  const pathname = usePathname();
  const router = useRouter();
  const currentSearchParams = useSearchParams();
  const activeFilter = currentSearchParams.get('filter') || filters[0];
  const filteredItems = filterBlogsByCategory(blogs, activeFilter);
  const filtersContainerRef = useRef<HTMLDivElement>(null);

  const updateFilter = (filter: string) => {
    const updatedSearchParams = new URLSearchParams(currentSearchParams.toString());

    if (filter.includes('All')) {
      updatedSearchParams.delete('filter');
    } else {
      updatedSearchParams.set('filter', filter);
    }

    router.push(pathname + '?' + updatedSearchParams.toString(), { scroll: false });
  };

  return (
    <>
      <div
        className="no-scrollbar mb-[3.75rem] flex flex-row gap-2 overflow-x-scroll"
        ref={filtersContainerRef}
      >
        {filters.map((filter, index) => (
          <FilterPill
            key={filter}
            isActive={activeFilter === filter}
            label={filter}
            onClick={() => {
              const scrollLeft = filtersContainerRef.current?.scrollLeft || 0;
              const pillWidth = filtersContainerRef.current?.children[index]?.clientWidth || 0;
              // @ts-expect-error - TS doesn't know that `offsetLeft` exists on `HTMLDivElement`
              const pillOffset = filtersContainerRef.current?.children[index]?.offsetLeft || 0;
              const scrollOffset = pillOffset - scrollLeft;
              const halfPillWidth = pillWidth / 2;
              const scrollPosition = scrollOffset - halfPillWidth;

              filtersContainerRef.current?.scrollBy({
                left: scrollPosition,
                behavior: 'smooth',
              });

              updateFilter(filter);
            }}
            className="transition-all"
          />
        ))}
      </div>
      <div className={'mb-10 flex flex-wrap gap-8 lg:mb-12 lg:flex-row lg:gap-5'}>
        {filteredItems.map((item) => (
          <BlogListItem key={item.id} {...{ item }} />
        ))}
      </div>
    </>
  );
};

export default BlogPostsWithFiltersContent;
