'use client';
import Video from 'react-youtube';

import { YoutubeVideoProps } from './types';

const YoutubeVideo = ({ videoId, ...props }: YoutubeVideoProps) => {
  return <Video videoId={videoId} {...props} />;
};

export default YoutubeVideo;
