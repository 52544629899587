'use client';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { Tooltip } from 'react-tooltip';
import { renderToString } from 'react-dom/server';

import { InfoIcon } from '@/public/assets/svg';
import TooltipContent from '@/components/atoms/TooltipContent';

import FeesColumn from './partials/FeesColumn';

/**
 * Props for `Fees`.
 */
export type FeesProps = SliceComponentProps<Content.FeesSlice>;

/**
 * Component for "Fees" Slices.
 */
const Fees = ({ slice }: FeesProps): JSX.Element => {
  const rows = slice.items.map((item) => ({
    icon: item.rowIcon,
    label: item.rowLabel,
    value: item.rowValue,
  }));

  const firstColumnRows = rows.slice(0, Math.ceil(rows.length / 2));
  const secondColumnRows = rows.slice(Math.ceil(rows.length / 2));

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="o-container o-container--xl pb-24 lg:pb-44"
    >
      <div className="flex flex-col gap-10 lg:flex-row lg:items-center lg:gap-[8.5rem]">
        <div className="order-2 flex flex-shrink-0 flex-col lg:order-1">
          <div className="mb-4 flex flex-row items-center justify-center gap-2 rounded-lg bg-primary-200 py-3.5">
            <p className="text-h8 font-bold text-primary-600">{slice.primary.tableHeader}</p>
            <Tooltip
              id="tooltip-icon"
              style={{
                backgroundColor: 'white',
                borderRadius: '.375rem',
              }}
            />
            <InfoIcon
              className="h-5 w-5 cursor-pointer text-primary-600 hover:opacity-50"
              data-tooltip-id="tooltip-icon"
              data-tooltip-html={renderToString(
                <TooltipContent
                  title={slice.primary.tooltipTitle as string}
                  description={slice.primary.tooltipDescription as string}
                />,
              )}
            />
          </div>
          <div className="grid grid-cols-1 grid-rows-1 gap-4 md:grid-cols-2">
            <FeesColumn rows={firstColumnRows} className="hidden md:block" />
            <FeesColumn rows={secondColumnRows} className="hidden md:block" />
            <FeesColumn rows={rows} className="md:hidden" />
          </div>
        </div>
        <div className="order-1 lg:order-2">
          <p className="mb-4 text-caption-1 font-bold text-primary-600">{slice.primary.subtitle}</p>
          <h2 className="text-h3 font-bold text-gray-600 md:text-h2">
            {slice.primary.titleFirstLine} {<br className="hidden lg:block" />}
            {slice.primary.titleSecondLine}
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Fees;
