import clsx from 'clsx';

import { FeesColumnProps } from './types';
import { PrismicNextImage } from '@prismicio/next';

const FeesColumn = ({ rows, className }: FeesColumnProps) => {
  return (
    <div className={clsx('min-w-[270px] rounded-lg bg-white p-6 shadow-elevation-3', className)}>
      {rows.map(({ label, icon, value }) => (
        <div
          className="flex justify-between border-t border-gray-200 py-4 first:border-none first:pt-0 last:pb-0"
          key={label}
        >
          <div className="flex items-center gap-5">
            <PrismicNextImage className="h-[1.375rem] w-[1.375rem] text-primary-600" field={icon} />
            <p className="text-body-7 font-medium text-gray-500">{label}</p>
          </div>
          <p className="text-body-7 font-bold text-gray-600">{value}</p>
        </div>
      ))}
    </div>
  );
};

export default FeesColumn;
