'use client';
import React, { useMemo } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { PrismicNextImage } from '@prismicio/next';
import useRWD from '@/hooks/useRWD';
import { defaultSettings } from './consts';
import { Props } from './types';

const ImageCarousel = ({ slides, settings = defaultSettings }: Props) => {
  const { isDesktop } = useRWD();

  const additionalFeatures = useMemo(() => {
    if (isDesktop && !!settings?.desktopFeatures?.autoplay) {
      return [
        Autoplay({
          delay: settings?.desktopFeatures?.autoplay,
          stopOnInteraction: false,
        }),
      ];
    }
    if (!isDesktop && !!settings?.mobileFeatures?.autoplay) {
      return [
        Autoplay({
          delay: settings?.mobileFeatures?.autoplay,
          stopOnInteraction: false,
        }),
      ];
    }
    return [];
  }, [isDesktop, settings?.desktopFeatures?.autoplay, settings?.mobileFeatures?.autoplay]);

  const [emblaRef] = useEmblaCarousel({ loop: true, align: 'center' }, additionalFeatures);

  return (
    <div className="relative">
      <div className="overflow-hidden pl-2 pr-4 lg:pl-0 lg:pr-0" ref={emblaRef}>
        <div className="grid w-full auto-cols-full grid-flow-col md:auto-cols-half lg:auto-cols-[40%]">
          {slides.map((item, index) => {
            return (
              <div className="flex px-2 lg:px-2.5" key={index}>
                <PrismicNextImage
                  className="min-h-full w-full overflow-hidden rounded-lg object-cover object-center lg:rounded-xl "
                  field={item.slide}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
