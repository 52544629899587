'use client';

import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';

import { FormInput } from '@/components/atoms/Input';
import { Content } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import { FormSelect } from '@/components/atoms/Select';
import { FormTextArea } from '@/components/atoms/TextArea';
import Button from '@/components/atoms/Button';
import ModalInfo from '@/components/molecules/ModalInfo';

import { StoreTypes } from './consts';
import { useHostArmForm } from './hooks';
import useRWD from '@/hooks/useRWD';
import useRecaptcha from '@/hooks/useRecaptcha';

/**
 * Props for `HostAtmForm`.
 */
export type HostAtmFormProps = SliceComponentProps<Content.HostAtmFormSlice>;

/**
 * Component for "HostAtmForm" Slices.
 */
const HostAtmForm = ({ slice }: HostAtmFormProps): JSX.Element => {
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const { isMobile } = useRWD();

  const methods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      phoneNumber: '',
      storeType: StoreTypes[0].value,
      country: '',
      provinceOrState: '',
      city: '',
      storeAddress: '',
      googleBusinessProfileLink: '',
      notes: '',
    },
  });

  const { reset: resetForm } = methods;
  const { onSubmit, isLoading } = useHostArmForm();

  const {
    recaptchaRef,
    recaptchaToken,
    handleRecaptchaChange,
    handleRecaptchaExpired,
    resetRecaptcha,
  } = useRecaptcha();

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="o-container o-container--xl my-44"
    >
      <div className="flex flex-col gap-5 lg:flex-row">
        <div className="flex items-center justify-center rounded-[.625rem] border border-gray-200 bg-white">
          <PrismicNextImage
            field={slice.primary.image}
            className="h-[500px] w-[282px] object-contain lg:h-[670px] lg:w-[440px]"
          />
        </div>
        <div className="flex flex-1 flex-col rounded-[.625rem] border border-gray-200 bg-white px-6 py-9 lg:p-10">
          <h3 className="mb-10 text-center text-h5 font-bold leading-9 text-gray-600 lg:text-body-2">
            {slice.primary.title}
          </h3>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(async (data) => {
                try {
                  if (!recaptchaToken) {
                    setIsModalErrorOpen(true);
                    return;
                  }

                  await onSubmit(data, recaptchaToken);
                  setIsModalSuccessOpen(true);
                  resetRecaptcha();
                  resetForm();
                } catch (error) {
                  setIsModalErrorOpen(true);
                }
              })}
              id="applyForm"
            >
              <div className="mb-5 flex w-full flex-col gap-5 lg:mb-6 lg:flex-row lg:gap-3">
                <FormInput
                  name="firstName"
                  label="First name"
                  placeholder="Enter your first name"
                  required
                />
                <FormInput
                  name="lastName"
                  label="Last name"
                  placeholder="Enter your last name"
                  required
                />
              </div>
              <div className="mb-5 flex w-full flex-col gap-5 lg:mb-6 lg:flex-row lg:gap-3">
                <FormInput
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  type="email"
                  required
                />
                <FormInput
                  name="companyName"
                  label="Company name"
                  placeholder="Enter your company name"
                  required
                />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormInput
                  name="phoneNumber"
                  label="Phone number"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormSelect name="storeType" label="Store type" options={StoreTypes} />
              </div>
              <div className="mb-5 flex w-full flex-col gap-5 lg:mb-6 lg:gap-3 xl:flex-row">
                <FormInput
                  name="country"
                  label="Country"
                  placeholder="Enter your country"
                  required
                />
                <FormInput
                  name="provinceOrState"
                  label="Province / State"
                  placeholder="Enter your province or state"
                  required
                />
                <FormInput name="city" label="City" placeholder="Enter your city" required />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormInput
                  name="storeAddress"
                  label="Store address"
                  placeholder="Enter your store address"
                  required
                />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormInput
                  name="googleBusinessProfileLink"
                  label="Google business profile link"
                  placeholder="Enter the URL"
                />
              </div>
              <div className="mb-5 flex w-full flex-row lg:mb-6">
                <FormTextArea name="notes" label="Notes" placeholder="Add your note here..." />
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                onChange={handleRecaptchaChange}
                onExpired={handleRecaptchaExpired}
                className="mb-10"
                size={isMobile ? 'compact' : 'normal'}
              />
              <Button type="submit" className="w-full" disabled={!recaptchaToken || isLoading}>
                <span>Send request</span>
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
      <ModalInfo
        isOpen={isModalSuccessOpen}
        title="Thank you!"
        description="We will contact you as soon as possible."
        onClose={() => setIsModalSuccessOpen(false)}
      />
      <ModalInfo
        isOpen={isModalErrorOpen}
        title="Something went wrong!"
        description="Please try again later or contact us directly."
        onClose={() => setIsModalErrorOpen(false)}
      />
    </section>
  );
};

export default HostAtmForm;
