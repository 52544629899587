import clsx from 'clsx';

import { InteracLogoIcon } from '@/public/assets/svg';
import { InteracFullLogoProps } from './types';

const InteracFullLogo = ({ className }: InteracFullLogoProps) => {
  return (
    <div className={clsx('flex flex-row items-center gap-3', className)}>
      <InteracLogoIcon className="h-9 w-9" />
      <p className="text-h8 font-semibold text-gray-500">INTERAC e-Transfer®</p>
    </div>
  );
};

export default InteracFullLogo;
