import RowWithImageSkeleton from '@/components/molecules/DesktopTable/partials/DesktopTableSkeleton/partials/RowWithImageSkeleton';
import React from 'react';

const MobileCryptoSkeleton = () => {
  return (
    <div className="flex w-full animate-pulse flex-col gap-4 lg:hidden">
      <div className="flex w-full justify-center rounded-lg bg-gray-200 py-3.5">
        <div className="h-6 w-28 rounded-sm bg-gray-300" />
      </div>
      <div className="flex w-full flex-col justify-center rounded-lg bg-gray-200 px-6">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="border-b border-b-gray-100 py-4">
            <RowWithImageSkeleton />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileCryptoSkeleton;
