import React, { ChangeEvent, useEffect, useState } from 'react';
import Modal from '@/components/molecules/Modal';
import Button from '@/components/atoms/Button';
import Close from '@/public/assets/svg/close.svg';
import Switch from '@/public/assets/svg/switch.svg';
import { MapFiltersType } from '@/utils/types';
import clsx from 'clsx';
import { defaultMapFilters } from '@/utils/consts';

type Props = {
  isOpen: boolean;
  filters: MapFiltersType;
  onApply: (newFilters: MapFiltersType) => void;
  onClose: () => void;
};

const SearchModal = ({ isOpen, onClose, filters, onApply }: Props) => {
  const [localFilters, setLocalFilters] = useState(filters);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setLocalFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    setLocalFilters((prev) => ({
      ...prev,
      [name]: type == 'checkbox' ? checked : value,
    }));
  };

  return (
    <Modal isOpen={isOpen} contentClassName="p-12 w-full max-w-[37.5rem]">
      <div className="flex justify-between px-5 py-4 text-body-5 font-bold">
        Filters <Close role="button" onClick={onClose} />
      </div>
      <div className="border-y border-gray-200 p-5">
        <span className="text-body-6 font-semibold">Service type</span>
      </div>
      <div className="px-4 py-5">
        <div className="mb-4 flex items-center gap-x-2">
          <input
            onChange={handleFilter}
            className="h-5 w-5 rounded-full border border-gray-300"
            type="radio"
            name="serviceType"
            value="buy"
            checked={localFilters.serviceType == 'buy'}
            id="buy"
          />
          <label htmlFor="buy">Buy</label>
        </div>
        <div className="flex items-center gap-x-2">
          <input
            onChange={handleFilter}
            className="h-5 w-5 rounded-full border border-gray-300"
            type="radio"
            name="serviceType"
            value="buy-and-sell"
            checked={localFilters.serviceType == 'buy-and-sell'}
            id="buy-and-sell"
          />
          <label htmlFor="buy-and-sell">Buy & Sell</label>
        </div>
      </div>
      <div className="px-5">
        <div className="border-t border-gray-300 py-5">
          <label className="text-body-6 font-semibold" htmlFor="openNow">
            Open Now
          </label>

          <Switch
            role="button"
            onClick={() =>
              setLocalFilters((prev) => ({
                ...prev,
                openNow: !prev.openNow,
              }))
            }
            className={clsx(
              'mt-4 last:child:transition-transform last:child:duration-300',
              localFilters.openNow && 'first:child:fill-green-600 last:child:translate-x-4',
            )}
          />
        </div>
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          onApply(localFilters);
        }}
        className="flex w-full justify-between gap-x-3 border-t border-gray-200 px-5 py-4"
      >
        <Button
          type="button"
          onClick={() => setLocalFilters(defaultMapFilters)}
          variant="error-outline"
          className="w-full"
        >
          Clear All
        </Button>
        <Button type="submit" className="w-full !text-gray-600">
          Apply
        </Button>
      </form>
    </Modal>
  );
};

export default SearchModal;
