'use client';

import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { LOCALES } from '@/i18nConfig';

import Link from '@/components/atoms/Link';
import { capitalizeSlug } from '@/utils';
import { ChevronDownIcon } from '@/public/assets/svg';
import { LOCALE_TO_COUNTRY_MAP } from '@/consts/locales';

import { TBreadCrumbProps } from './types';

const Breadcrumbs = ({
  homeElement,
  separator,
  containerClasses,
  listClasses,
  activeClasses,
  start = 0,
  lang,
}: TBreadCrumbProps) => {
  const paths = usePathname().replace(new RegExp(`(${Object.values(LOCALES).join('|')})`, 'i'), '');

  const strippedPath = Object.values(LOCALES).reduce((acc, locale) => {
    if (acc.startsWith(`/${locale}`)) {
      return acc.replace(`/${locale}`, '');
    }

    return acc;
  }, paths);

  const pathNames = strippedPath
    .split('/')
    .filter((path) => path)
    .slice(start);

  const slug = capitalizeSlug(
    pathNames.length > 1 ? pathNames[pathNames.length - 2] : pathNames[0],
  );

  return (
    <div>
      <ul className={containerClasses}>
        {!!homeElement && (
          <li className={listClasses}>
            <Link href={'/'}>{homeElement}</Link>
          </li>
        )}
        {pathNames.length > 0 && !!homeElement && separator}
        {pathNames.map((link, index) => {
          const href = `/${pathNames.slice(0, index + 1).join('/')}`;
          const itemClasses =
            strippedPath.replace(/\//g, '') === href.replace(/\//g, '')
              ? `${listClasses} ${activeClasses}`
              : listClasses;

          let itemLink = link.replace(/[^A-Z0-9]/gi, ' ');
          itemLink = itemLink === 'guide' ? 'guides' : itemLink;
          itemLink = itemLink === 'blog' ? 'blogs' : itemLink;

          if (itemLink === 'bitcoin atm') {
            // Hong Kong locale uses /bitcoin-atm/hong-kong/ for locations homepage
            if (lang === LOCALES.EN_HK || lang === LOCALES.ZH_HK) {
              return null;
            }

            const countryName =
              LOCALE_TO_COUNTRY_MAP[lang || LOCALES.EN_CA] || LOCALE_TO_COUNTRY_MAP[LOCALES.EN_CA];
            itemLink = countryName;
          }

          return (
            <li
              key={index}
              className={clsx('items-center', {
                'hidden lg:flex': pathNames.length >= 4,
                flex: pathNames.length < 4,
              })}
            >
              <p className={itemClasses}>
                <Link className="capitalize" href={href}>
                  {itemLink}
                </Link>
              </p>
              {pathNames.length !== index + 1 && separator}
            </li>
          );
        })}
        <li>
          <Link
            className={clsx('flex items-center gap-x-2.5 text-body-8 font-semibold lg:hidden', {
              hidden: pathNames.length < 4,
            })}
            href={`/${pathNames.slice(0, -1).join('/')}`}
          >
            <ChevronDownIcon className="h-4 w-4 rotate-90" />
            <span>Back to {slug}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
