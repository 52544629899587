'use client';
import clsx from 'clsx';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import { TabsProps } from './types';

const Tabs = ({ items, defaultIndex }: TabsProps) => {
  return (
    <TabGroup
      defaultIndex={defaultIndex || 0}
      className="flex flex-col items-center overflow-hidden"
    >
      {items.length > 1 && (
        <TabList className="z-20 mb-14 flex gap-1 rounded-full bg-white p-1 md:mb-20">
          {items.map(({ name }) => (
            <Tab
              key={name}
              className={clsx(
                'w-[5.625rem] rounded-full py-2 outline-none transition-all duration-300 data-[selected]:bg-primary-600 data-[selected]:outline-none',
                'text-button font-medium text-gray-600',
              )}
            >
              {name}
            </Tab>
          ))}
        </TabList>
      )}
      <TabPanels className="z-20 w-full">
        {items.map(({ name, Content }) => (
          <TabPanel key={name}>{Content}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};

export default Tabs;
