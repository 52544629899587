const CryptoCarouselItemSkeleton = () => {
  return (
    <div className="relative mb-10 mr-5 mt-16 flex min-w-mobileSlider animate-pulse flex-col items-center overflow-visible rounded-[0.625rem] border border-gray-200 px-8 pb-8 pt-9 shadow-elevation-4 lg:mb-12 lg:mt-28 lg:min-w-slider lg:px-7">
      <div className="absolute left-1/2 top-0 z-10 h-12 w-12 -translate-x-1/2 -translate-y-1/2 rounded-full bg-gray-200" />
      <div className="my-2 h-5 w-1/2 rounded bg-gray-200" />
      <div className="mx-auto mb-6 mt-2 h-4 w-1/4 rounded bg-gray-200" />
      <div className="flex w-full justify-between border-t border-gray-200 py-5">
        <div className="flex w-full flex-row items-center">
          <div className="mr-2 h-4 w-4 rounded bg-gray-200" /> Buy
        </div>
        <span className="h-5 w-16 rounded bg-gray-200 text-h8 font-bold"></span>
      </div>
      <div className="flex w-full flex-row items-center justify-between border-t border-gray-200 pt-5">
        <div className="flex w-full flex-row items-center">
          <div className="mr-2 h-4 w-4 rounded bg-gray-200" /> Sell
        </div>
        <span className="h-5 w-16 rounded bg-gray-200 text-h8 font-bold"></span>
      </div>
    </div>
  );
};

export default CryptoCarouselItemSkeleton;
