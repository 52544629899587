import { ChangeEvent, useRef, useState } from 'react';
import {
  Combobox as HeadlessCombobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import clsx from 'clsx';

import { ComboboxProps } from '@/components/organisms/Hero/partials/LocationSelect/types';
import { LOCATION_SEARCH_INPUT_NAME } from '@/utils/consts';

const SearchInput = ({
  value,
  options,
  onInputChange,
  onFocus = () => null,
  noOptionsMessage,
  onChange,
  LeftIcon,
  isLoading,
  placeholder,
}: ComboboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onInputChange(newValue);
    setSelectedOption(null);
  };

  const handleFocus = () => {
    if (!isFocused) {
      onChange(null);
      setIsFocused(true);
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const displayValue = () => {
    if (!selectedOption) return '';
    return selectedOption.label;
  };

  return (
    <HeadlessCombobox
      value={selectedOption}
      onChange={(option) => {
        setSelectedOption(option);
        onChange(option);
      }}
    >
      <div className="relative z-40 h-full">
        <div
          className={clsx(
            'relative flex h-full w-full cursor-default items-center gap-2 overflow-hidden rounded-md border border-gray-200 bg-white p-3 text-left',
            'focus:round-b-none hover:border-gray-300 focus:border-primary-500',
            {
              'border-primary-500': isFocused,
              'border-gray-200': !isFocused,
            },
          )}
        >
          {LeftIcon && <LeftIcon className="min-h-5 min-w-5 scale-90" width={20} height={20} />}
          <ComboboxInput
            name={LOCATION_SEARCH_INPUT_NAME}
            className={clsx(
              'w-full border-none text-button text-gray-600 caret-primary-600 shadow-none focus:outline-none',
            )}
            displayValue={displayValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            ref={inputRef}
          />
        </div>
        <ComboboxOptions className="absolute z-50 max-h-60 w-full overflow-auto rounded-md rounded-t-none border border-t-0 border-gray-200 bg-white">
          {options.length > 0 ? (
            options.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option}
                className="relative w-full cursor-pointer select-none px-3 py-3.5 text-button text-gray-600 hover:bg-gray-50"
              >
                {option.label}
              </ComboboxOption>
            ))
          ) : (
            <div className="px-3 py-3.5 text-button text-gray-600">
              {isLoading ? 'Loading...' : noOptionsMessage || 'No options.'}
            </div>
          )}
        </ComboboxOptions>
      </div>
    </HeadlessCombobox>
  );
};

export default SearchInput;
