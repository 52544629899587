'use client';

import { PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';

const RenderInView = ({ children }: PropsWithChildren) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return <div ref={ref}>{inView && children}</div>;
};

export default RenderInView;
