'use client';
import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { RichTextField } from '@prismicio/client';
import { MessageQuestionIcon } from '@/public/assets/svg';

import Add from '/public/assets/svg/add.svg';
import Minus from '/public/assets/svg/minus.svg';
import RichText from '@/components/atoms/RichText';

type FaqItemProps = {
  header: string | null;
  copy: RichTextField | null;
  toggleItem: () => void;
  isOpen: boolean;
};

const FaqItem: React.FC<FaqItemProps> = ({ header, copy, toggleItem, isOpen }): ReactElement => {
  return (
    <div className="mb-4 rounded-[0.625rem] border border-gray-200 px-4 shadow-elevation-4 lg:mb-5 lg:px-6 lg:py-2 lg:shadow-elevation-3">
      <button aria-label="faq item" onClick={toggleItem} className="flex w-full py-4">
        <MessageQuestionIcon className="hidden h-7 min-w-7 lg:block" />
        <h3 className="w-full px-4 text-center text-body-6 font-semibold text-gray-600">
          {header}
        </h3>
        {isOpen ? <Minus className="h-7 min-w-7" /> : <Add className="h-7 min-w-7" />}
      </button>
      <div className={clsx('overflow-hidden', isOpen ? 'max-h-[9999px]' : 'max-h-0')}>
        <div
          className={clsx(
            'rich-text sticky z-0 pb-4 text-body-7 text-gray-500 lg:pb-6',
            isOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0',
          )}
        >
          <RichText field={copy} />
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
